import request from '@/utils/request'

// 查询新闻或图片
export function getConfigQuery (type) {
    const data = {
        type: type
    }
    return request({
        url: '/config/query',
        method: 'get',
        params: data
    })
}
