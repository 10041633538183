
import axios from 'axios'
import { Message } from 'element-ui'
// import QS from 'qs'

// 创建axios实例
const service = axios.create({
    baseURL: '/', // process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request拦截器
service.interceptors.request.use(
    config => {
        // 通过参数 兼容多种 Content-Type
        // if(config.contentType === 'x-www-form-urlencoded') {
        //     config.data = QS.stringify(config.data)
        //     config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
        // }

        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        /**
         * code为非A00000 时抛错，可结合自己业务进行修改
         */
        const res = response.data
        if (res.code !== 200 && res.code !== 'A00000') {
            if (res.message) {
                Message(res.message)
            } else if (res.msg) {
                Message(res.msg)
            }
        }
        return res
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
